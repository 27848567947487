import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public constructor(private readonly titleService: Title) {}

  public ngOnInit(): void {
    this.titleService.setTitle(`${environment.title} Admin`);
  }
}
